import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ClaimType } from 'src/types';
import moment, { Moment } from 'moment';
import { ClaimForm, ClaimFormArray, ClaimFormGroup, PayeeFormGroup } from '../edit-case-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddClaimEvidenceDialogComponent } from '../add-claim-evidence-dialog/add-claim-evidence-dialog.component';
import { GetEditCaseResponse } from 'src/app/services/cases/cases.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-claims-form',
  templateUrl: './claims-form.component.html',
  styleUrls: ['./claims-form.component.scss']
})
export class ClaimsFormComponent implements OnChanges {
  @Input() legalRelationship: number;
  @Input() claims: ClaimFormArray;
  @Input() claimTypes: ClaimType[] = [];
  claimTypeOptions: number[] = [];

  @Input() addClaim: (claim?: GetEditCaseResponse['claims'][number]) => void = () => {};
  @Input() addClaimFile: (claimForm: ClaimFormGroup, file: ClaimForm['files'][number] | File) => PayeeFormGroup<ClaimForm['files'][number]>;

  max = moment().subtract(1, 'day');

  constructor(
    private dialog: MatDialog,
    private claimsService: ClaimsService,
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.claimTypes || changes.legalRelationship) {
      await this.filterClaimTypeOptions();
    }
  }

  minDate(claimForm: ClaimFormGroup): string | Moment | null {
    return claimForm.value.files[0]?.invoice_date || null;
  }

  removeClaim(index: number): void {
    this.claims.removeAt(index);
  }

  readonly selectedClaimDisplayWith = (claimForm?: ClaimFormGroup): string => {
    const index = this.claims.controls?.findIndex(form => form === claimForm);
    const id = claimForm?.getRawValue()?.id || 'Piszkozat követelés';
    if (index < 0) {
      return id;
    } else {
      return `${index + 1}. ${id}`;
    }
  };

  showClaimTypeSpecification(claimForm: ClaimFormGroup): boolean {
    const claimType = this.claimTypes.find(ct => ct.id === claimForm.value.claimType);
    return claimType?.internal_id === '99-a';
  }

  removeEvidence(claimForm: ClaimFormGroup, fileIndex: number): void {
    const files = claimForm.get('files') as FormArray;
    files.removeAt(fileIndex);
  }

  editClaimFile(file: FormGroup): void {
    this.dialog.open(AddClaimEvidenceDialogComponent, {
      data: file,
    });
  }

  openClaimFile(file: ClaimForm['files'][number]): void {
    const storage_url = file.storage_url;
    if (typeof storage_url === 'string') {
      window.open(storage_url, '_blank');
    } else {
      window.open(URL.createObjectURL(storage_url), '_blank');
    }
  }

  claimEvidencesDropped(files: FileList, claimForm: ClaimFormGroup): void {
    if (files.length === 0) {
      return;
    }

    const file = files.item(0)!;
    const formGroup = this.addClaimFile(claimForm, file);
    this.dialog.open(AddClaimEvidenceDialogComponent, {
      data: formGroup,
    });
  }

  readonly claimTypeDisplayedWith = (id: number): string => {
    return this.claimTypes.find(ct => ct.id === id)?.label || '';
  };

  private async filterClaimTypeOptions(): Promise<void> {
    if(!this.legalRelationship) {
      this.claimTypeOptions = this.claimTypes.map(ct => ct.id);
    } else {
      this.claimTypeOptions = await this.claimsService.getAllowedClaimTypes(this.legalRelationship);

      this.claims.controls.forEach(control => {
        const claimType = control.value.claimType;
        if (!claimType) {
          return;
        }
        if (!this.claimTypeOptions.includes(claimType)) {
          control.patchValue({
            claimType: null,
          });
        }
      });
    }
  }

  readonly environment = environment;
}
