<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="title top-margin-20">Adós személyes adatainak rögzítése</h1>
  </ng-template>
  <div class="help-text">
    <div>
      A végrehajtás elindításához kérjük adja meg az adós alábbi személyes
      adatait.
    </div>
    <div class="top-margin-20">
      <span>Amennyiben nem rendelkezik ezekkel az adatokkal,</span>
      <a> ebben a tudástár bejegyzésben összefoglaltuk</a>
      <span>, hogyan tudja azokat beszerezni.</span>
    </div>
  </div>

  <form [formGroup]="form" class="top-margin-10">
    <payee-form-field width="wide" height="taller">
      <input
        payee-input
        placeholder="Adós édesanyjának neve"
        formControlName="mother_name"
      />
      <ng-container
        payee-error
        *ngIf="mother_name.invalid && mother_name.dirty"
      >
        <span *ngIf="mother_name.hasError('required')">
          Mező kitöltése kötelező
        </span>
        <span *ngIf="mother_name.hasError('maxlength')">
          A megadott szöveg túllépi a megengedett karakterszámot.
        </span>
      </ng-container>
    </payee-form-field>
    <payee-form-field width="wide" height="taller">
      <input
        payee-input
        placeholder="Adós születési helye"
        formControlName="birth_place"
      />
      <ng-container
        payee-error
        *ngIf="birth_place.invalid && birth_place.dirty"
      >
        <span *ngIf="birth_place.hasError('required')">
          Mező kitöltése kötelező
        </span>
        <span *ngIf="birth_place.hasError('maxlength')">
          A megadott szöveg túllépi a megengedett karakterszámot.
        </span>
      </ng-container>
    </payee-form-field>
    <payee-form-field width="wide" height="taller">
      <payee-datepicker
        [max]="max"
        placeholder="Adós születési dátuma (Év, hónap, nap)"
        formControlName="birth_date"
      ></payee-datepicker>
      <ng-container payee-error *ngIf="birth_date.invalid && birth_date.dirty">
        <span *ngIf="birth_date.hasError('required')">
          Mező kitöltése kötelező
        </span>
        <span *ngIf="birth_date.hasError('maxlength')">
          A megadott szöveg túllépi a megengedett karakterszámot.
        </span>
      </ng-container>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      <div class="flex vcenter hcenter">
        <span>Rögzítem az adós személyes adatait</span>
        <payee-icon name="arrow-right-white" height="10px"></payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button payee-button width="wide" mat-dialog-close color="white" [isBorderButton]="true">
      <div class="flex vcenter hcenter">
        <span>Mégsem</span>
        <payee-icon name="arrow-right-grey" height="10px"></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
