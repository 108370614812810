import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-client-upload-contract-dialog',
  templateUrl: './client-upload-contract-dialog.component.html',
  styleUrls: ['./client-upload-contract-dialog.component.scss']
})
export class ClientUploadContractDialogComponent {
  files: File[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private dialogRef: MatDialogRef<ClientUploadContractDialogComponent>,
  ) { }

  filesDropped(files: FileList) {
    for (let i = 0; i < files.length; ++i) {
      const file = files.item(i);
      if (file) {
        this.files.push(file);
      }
    }
  }

  async submit(): Promise<void> {
    if (this.files.length <= 0) {
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: {
        legal_relationship_files: this.files,
      },
    };

    this.dialogRef.close(closeValue);
  }
}
