import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { CasesService, CasesTableData, GetEditCaseResponse } from 'src/app/services/cases/cases.service';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { EditCaseDialogComponent } from '../../../../helper/edit-case-dialog/edit-case-dialog.component';
import { first } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { CopyCaseWithProductDialogComponent } from 'src/app/helper/copy-case-with-product-dialog/copy-case-with-product-dialog.component';
import { RestoreCaseDialogComponent } from 'src/app/helper/restore-case-dialog/restore-case-dialog.component';
import { PartnerLogoService } from 'src/app/shared/services/partner-logo/partner-logo.service';

@Component({
  selector: 'app-cases-table',
  templateUrl: './cases-table.component.html',
  styleUrls: ['./cases-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CasesTableComponent implements OnChanges, OnDestroy {
  readonly dataSource = new MatTableDataSource<CasesTableData>();
  readonly filterHeader: string[] = [];
  displayedColumns: string[] = [
    'detailsToggle',
    'payeeId',
    'debtors',
    'status',
    'partner',
    'claimsSumAmount',
    'isDeleted',
    'actions',
  ];

  copyTooltipDisabled = true;

  @Input() data: CasesTableData[];
  @Input() length?: number;

  expandedElement: CasesTableData | null = null;
  showAllLogs = false;
  lockExpandedElement = false;

  @Input() pageSize: number;
  @Output() pageSizeChange = new EventEmitter<number>();

  @Input() pageIndex: number;
  @Output() pageIndexChange = new EventEmitter<number>();

  @Output() page = new EventEmitter<PageEvent>();
  @Output() filterCase = new EventEmitter<string>();

  private readonly killSub = new Subject<void>();

  get showCaseLinks(): boolean { return !environment.production || !!this.usersService.userSubject.value?.can_update_product_price; }
  get appUrl(): string { return environment.appUrl; }
  get paymentUrl(): string { return environment.paymentUrl; }
  get getPartnerLogoName() { return this.partnerLogoService.getPartnerMiniLogoName; }

  constructor(
    private workflowService: WorkflowService,
    private casesService: CasesService,
    private dialog: MatDialog,
    private usersService: UsersService,
    private partnerLogoService: PartnerLogoService,
  ) { }

  ngOnDestroy(): void {
    this.killSub.next();
    this.killSub.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource.data = changes.data.currentValue;
    }
  }

  restoreCase(element: CasesTableData): void {
    const dialogRef = this.dialog.open(RestoreCaseDialogComponent, {
      data: element,
      position: {
        top: '50px',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe({
        next: async (data?: boolean) => {
          if (!data) {
            return;
          }

          try {
            await this.casesService.restoreCase(element.id);

            const updatedData = await this.casesService.getCases({
              payee_case_id: element.payee_case_reference_id,
            });
            if (updatedData.data.length > 0) {
              Object.assign<CasesTableData, Partial<CasesTableData>>(element, updatedData.data[0]);
            }
          } catch (error) {
            console.error('Error while restoring case', error);
          }
        }
      });
  }

  openEditCaseDialog(caseId: string) {
    const dialogRef = this.dialog.open(EditCaseDialogComponent, {
      data: caseId,
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe({
        next: async (data?: GetEditCaseResponse) => {
          if (!data) {
            return;
          }

          const caseData = this.data.find(c => c.id === caseId);
          if (!caseData) {
            return;
          }

          const updatedData = await this.casesService.getCases({
            payee_case_id: caseData.payee_case_reference_id,
          });
          if (updatedData.data.length > 0) {
            Object.assign<CasesTableData, Partial<CasesTableData>>(caseData, updatedData.data[0]);
          }
        }
      });
  }

  openCopyCaseDialog(caseId: string) {
    const dialogRef = this.dialog.open(CopyCaseWithProductDialogComponent, {
      data: caseId,
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe({
        next: async (updatedData?: CasesTableData) => {
          if (!updatedData) {
            return;
          }

          const caseData = this.data.find(c => c.id === caseId);
          if (!caseData) {
            return;
          }

          this.filterCase.next(caseData.payee_case_reference_id);
        }
      });
  }

  pageChange(event: PageEvent) {
    if (this.pageSize !== event.pageSize) {
      this.pageSize = event.pageSize;
      this.pageSizeChange.emit(event.pageSize);
    }
    if (this.pageIndex !== event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.pageIndexChange.emit(event.pageIndex);
    }

    this.page.emit(event);
  }

  onActionsMenuOpen(element: CasesTableData) {
    this.lockExpandedElement = true;
  }

  onActionsMenuClosed() {
    this.lockExpandedElement = false;
  }

  async runTransitionWithDialog(element: CasesTableData, event: CasesTableData['actions'][number]) {
    const res = await this.workflowService.runTransitionWithDialog({
      transitionId: event.id,
      caseId: element.id,
      dialog: event.dialog,
    });
    if (res) {
      const fresh = await this.casesService.getCases({
        payee_case_id: element.payee_case_reference_id,
        page: 1,
      });
      this.dataSource.data = this.data = fresh.data;
    }
  }

  updateExpandedElement(element: CasesTableData) {
    if (this.lockExpandedElement) {
      return;
    }

    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }

  async openFormOfElement(element: CasesTableData): Promise<void> {
    // TODO
    // const formUrl = element.case.appSiteUrl;
    // const isSzamlazzHu = this.szamlazzhuService.isCaseSzamlazzhu(element.case);
    // if (!isSzamlazzHu) {
    //   window.open(formUrl, '_blank');
    //   return;
    // }
    // const shortToken = await this.szamlazzhuService.createShortTokenForCase(element.client, element.case);
    // const szamlazzhuUrl = `${formUrl}?short_token=${shortToken}`;
    // window.open(szamlazzhuUrl, '_blank');
  }

  filterHistoricCase(id: string): void {
    this.filterCase.emit(id);
  }

  readonly environment = environment;
}
