import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';

import { AddDeliveryAddressComponent } from './add-delivery-address/add-delivery-address.component';
import { AttachmentDeadlineDialogModule } from './attachment-deadline-dialog/attachment-deadline-dialog.module';
import { AttachmentNoDeadlineDialogModule } from './attachment-no-deadline-dialog/attachment-no-deadline-dialog.module';
import { CloseCaseComponent } from './close-case/close-case.component';
import { DiscardCaseComponent } from './discard-case/discard-case.component';
import { FullPaymentStatementComponent } from './full-payment-statement/full-payment-statement.component';
import { InstallmentComponent } from './installment/installment.component';
import { ManualPaymentComponent } from './manual-payment/manual-payment.component';
import { MissedInstallmentReportComponent } from './missed-installment-report/missed-installment-report.component';
import { MissingDataGeneralComponent } from './missing-data-general/missing-data-general.component';
import { NoAttachmentNoDeadlineDialogModule } from './no-attachment-no-deadline-dialog/no-attachment-no-deadline-dialog.module';
import { OverwriteStateDialogComponent } from './overwrite-state-dialog/overwrite-state-dialog.component';
import { RealEstateExecutionComponent } from './real-estate-execution/real-estate-execution.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PayeeHorizontalLineModule } from '../payee-horizontal-line/payee-horizontal-line.module';
import { ExecuteEventDialogComponent } from './execute-event-dialog/execute-event-dialog.component';
import { FullPaymentWarningComponent } from './full-payment-warning/full-payment-warning.component';
import { GenerateAttorneyDataDialogComponent } from './generate-attorney-data-dialog/generate-attorney-data-dialog.component';
import { StartExecutionDialogComponent } from './start-execution-dialog/start-execution-dialog.component';
import { ClientUploadContractDialogComponent } from './client-upload-contract-dialog/client-upload-contract-dialog.component';
import { DebtorIndDataDialogModule } from './debtor-ind-data-dialog/debtor-ind-data-dialog.module';
import { CaseAssignedToBailiffDialogModule } from './case-assigned-to-bailiff-dialog/case-assigned-to-bailiff-dialog.module';

@NgModule({
  declarations: [
    AddDeliveryAddressComponent,
    CloseCaseComponent,
    DiscardCaseComponent,
    ExecuteEventDialogComponent,
    FullPaymentStatementComponent,
    FullPaymentWarningComponent,
    GenerateAttorneyDataDialogComponent,
    InstallmentComponent,
    ManualPaymentComponent,
    MissedInstallmentReportComponent,
    MissingDataGeneralComponent,
    OverwriteStateDialogComponent,
    RealEstateExecutionComponent,
    StartExecutionDialogComponent,
    ClientUploadContractDialogComponent,
  ],
  imports: [
    AttachmentDeadlineDialogModule,
    AttachmentNoDeadlineDialogModule,
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NoAttachmentNoDeadlineDialogModule,
    PayeeButtonsModule,
    PayeeDropzoneModule,
    PayeeFormFieldsModule,
    PayeeHorizontalLineModule,
    PayeeIconModule,
    PayeeInputModule,
    PayeePopupModule,
    ReactiveFormsModule,
    DebtorIndDataDialogModule,
    CaseAssignedToBailiffDialogModule,
  ]
})
export class WorkflowDialogModule { }
