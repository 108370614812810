<payee-info
  class="bottom-margin-15"
  [style.--height]="'auto'"
  [style.--width]="'1100px'"
>
  <h1>A szűrő működése</h1>
  <ul style="list-style-type: disc">
    <li>Alapból pontos egyezésekre keres</li>
    <li><i>-valami</i>-ra azok nem fognak megjelenni, amik egyeznek</li>
    <li>
      <i>*</i> egy "wildcard", pl. <i>*valami</i>-ra azok meg fognak jelenni,
      amik <i>valami</i>-ra végződnek, <i>valami*</i>-ra azok meg fognak
      jelenni, amik <i>valami</i>-val kezdődnek
    </li>
    <li>
      Meg lehet adni több szűrőt <i>vagy</i> kapcsolattal, szóközzel elválasztva
    </li>
    <li>
      Ha a szűrőben van szóköz, vagy kötőjel, pl. <i>Teszt Béla</i>, akkor azt
      idézőjelek közé kell rakni: <i>"Teszt Béla"</i>
    </li>
  </ul>
  <div>Néhány példa:</div>
  <div class="flex">
    <span>Adószám törzsszáma:&nbsp;</span>
    <i>12345678*</i>
  </div>
  <div class="flex">
    <span
      >Legal Labs számlái, kivéve, amiket a Teszt Bélának állított
      ki:&nbsp;</span
    >
    <i>"Legal Labs" -"Teszt Béla"</i>
  </div>
</payee-info>

<div class="flex vcenter">
  <payee-form-field [style.--width]="'1100px'">
    <input
      type="text"
      [formControl]="searchControl"
      payee-input
      placeholder="Számla azonosító, hitelező neve/adószáma, adós neve/adószáma"
    />
  </payee-form-field>
  <mat-progress-spinner
    mode="indeterminate"
    diameter="24"
    *ngIf="loading"
  ></mat-progress-spinner>
</div>

<table
  mat-table
  multiTemplateDataRows
  [dataSource]="dataSource"
  matSort
  [matSortDisabled]="loading"
  (matSortChange)="sortChange()"
  class="mat-elevation-z1 top-margin-15"
  id="invoice-table"
>
  <ng-container matColumnDef="invoice_number">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Számla Azonosító</th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoice_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Számla Típusa</th>
    <td mat-cell *matCellDef="let element">
      {{ invoiceTypeLabel[element.type] ?? element.type }}
    </td>
  </ng-container>

  <ng-container matColumnDef="client_name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Hitelező Neve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.client_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="client_tax_number">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Hitelező Adószáma</th>
    <td mat-cell *matCellDef="let element">
      {{ element.client_tax_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debtor_name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Adós Neve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.debtor_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debtor_tax_number">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Adós Adószáma</th>
    <td mat-cell *matCellDef="let element">
      {{ element.debtor_tax_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Számla Összege</th>
    <td mat-cell *matCellDef="let element">
      <span
        >{{ element.remaining_amount | number : "1.0" : "hu" }}&nbsp;{{
          element.currency_iso
        }}</span
      >
      <span color="grey-dark"
        >&nbsp;/&nbsp;{{
          element.original_amount | number : "1.0" : "hu"
        }}&nbsp;{{ element.currency_iso }}</span
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="due_date_at">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Fizetési Határidő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.due_date_at | date : "yyyy.MM.dd." }}
    </td>
  </ng-container>

  <ng-container matColumnDef="issued_at">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Számla Kelte</th>
    <td mat-cell *matCellDef="let element">
      {{ element.issued_at | date : "yyyy.MM.dd." }}
    </td>
  </ng-container>

  <ng-container matColumnDef="detail-column">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div *ngIf="expandedElement === element">
        <mat-progress-spinner
          *ngIf="!expandedDetails"
          mode="indeterminate"
        ></mat-progress-spinner>
        <div *ngIf="expandedDetails" class="flex">
          <div
            class="flex column"
            *ngIf="expandedDetails.corrections.length > 0"
          >
            <h2>Helyesbítő számlák</h2>
            <table>
              <tr>
                <th>Számla azonosító</th>
                <th>Számla összege</th>
                <th>Fizetési határidő</th>
                <th>Számla kelte</th>
              </tr>
              <tr *ngFor="let correction of expandedDetails.corrections">
                <td>{{ correction.invoice_number }}</td>
                <td>
                  {{ correction.remaining_amount | number : "1.0" : "hu" }} /
                  {{ correction.original_amount | number : "1.0" : "hu" }}
                </td>
                <td>{{ correction.due_date_at | date : "yyyy.MM.dd." }}</td>
                <td>{{ correction.issued_at | date : "yyyy.MM.dd." }}</td>
              </tr>
            </table>
          </div>
          <div
            class="flex column"
            *ngIf="expandedDetails.payee_cases.length > 0"
          >
            <h2>Ügyek</h2>
            <table>
              <tr>
                <th>Ügy azonosító</th>
                <th>Termék</th>
                <th>Státusz</th>
              </tr>
              <tr *ngFor="let payee_case of expandedDetails.payee_cases">
                <td>{{ payee_case.payee_case_reference_id }}</td>
                <td>{{ payee_case.product_label }}</td>
                <td>{{ payee_case.state_label }}</td>
                <td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row"
    (click)="updateExpandedElement(element)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: ['detail-column']"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [disabled]="loading"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  (page)="pageChange($event)"
  class="mat-elevation-z1"
></mat-paginator>
