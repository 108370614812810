<payee-popup
  title="Ügykiosztás rögzítése"
  description="Kérlek, rögzítsd a négy számjegyből álló jelvényszámot. Pl.: 0123"
>
  <form [formGroup]="form">
    <payee-form-field width="wide">
      <input
        payee-input
        placeholder="Jelvényszám"
        formControlName="bailiff_information"
      />
      <ng-container
        *ngIf="bailiff_information.invalid && bailiff_information.dirty"
        payee-error
      >
        <span *ngIf="bailiff_information.hasError('required')">
          Mező kitöltése kötelező!
        </span>
        <span *ngIf="bailiff_information.hasError('pattern')">
          Helytelen formátum!
        </span>
      </ng-container>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      <div class="flex vcenter hcenter">
        <span>Rögzítés</span>
        <payee-icon height="10px" name="arrow-right-white"></payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="white"
      [isBorderButton]="true"
      width="wide"
      mat-dialog-close
    >
      <div class="flex vcenter hcenter">
        <span>Mégsem</span>
        <payee-icon height="10px" name="arrow-right-grey"></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
