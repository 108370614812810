<payee-popup
  title="Szerződés rögzítése az ügyhöz"
  description="
    A szerződés rögzítése után azonnal megkezdjük az ügy feldolgozását.
    Az ön jogi biztonsága érdekében kérjük töltsön fel egy - az adós és ön között létrejött - dokumentumot az alábbiak közül.
  "
>
  <ul class="file-examples top-margin-10">
    <li>Szerződés (akár email levelezés PDF-ben lementve)</li>
    <li>ÁSZF</li>
    <li>Megrendelési visszaigazolás</li>
    <li>Teljesítési igazolás</li>
  </ul>

  <payee-dropzone
    class="top-margin-25"
    title=""
    [multiple]="true"
    description="Húzza ide a szerződéshez kapcsolódó fájlokat (Max. 10MB)"
    (dropped)="filesDropped($event)"
  ></payee-dropzone>

  <div
    *ngIf="files.length > 0"
    class="flex column files top-margin-15"
  >
    <div *ngFor="let file of files; let i = index" class="file flex vcenter">
      <payee-icon name="upload-paper"></payee-icon>
      <span>{{ file.name }}</span>
      <button
        type="button"
        payee-icon-button
        color="white"
        (click)="files.splice(i, 1)"
      >
        <payee-icon name="trash-red"></payee-icon>
      </button>
    </div>
  </div>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      <div class="flex vcenter hcenter">
        <span>Rögzítem a szerződést az ügyhöz</span>
        <payee-icon height="10px" name="arrow-right-white"></payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="white"
      [isBorderButton]="true"
      width="wide"
      mat-dialog-close
    >
      <div class="flex vcenter hcenter">
        <span>Mégsem</span>
        <payee-icon height="10px" name="arrow-right-grey"></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
