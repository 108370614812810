<payee-popup
  title="Ügy helyreállítása"
  description="Az ügyet kitörölték. Biztosan helyre akarod állítani?"
>
  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" [mat-dialog-close]="true">
      Helyreállítás
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Bezárás
    </button>
  </ng-template>
</payee-popup>
