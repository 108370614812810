import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-case-assigned-to-bailiff-dialog',
  templateUrl: './case-assigned-to-bailiff-dialog.component.html',
  styleUrls: ['./case-assigned-to-bailiff-dialog.component.scss']
})
export class CaseAssignedToBailiffDialogComponent {
  readonly form: FormGroup;

  get bailiff_information(): FormControl { return this.form.get('bailiff_information') as FormControl; }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private dialogRef: MatDialogRef<CaseAssignedToBailiffDialogComponent>,
  ) {
    this.form = this.fb.group({
      bailiff_information: [null, [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });
  }

  submit(): void {
    if (this.form.invalid) {
      this.bailiff_information.markAsDirty();
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: {
        bailiff_information: this.bailiff_information.value,
      },
    };

    this.dialogRef.close(closeValue);
  }
}
