import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-debtor-ind-data-dialog',
  templateUrl: './debtor-ind-data-dialog.component.html',
  styleUrls: ['./debtor-ind-data-dialog.component.scss']
})
export class DebtorIndDataDialogComponent {
  readonly form: FormGroup;

  readonly max = new Date();

  get birth_date(): FormControl { return this.form.get('birth_date') as FormControl; }
  get birth_place(): FormControl { return this.form.get('birth_place') as FormControl; }
  get mother_name(): FormControl { return this.form.get('mother_name') as FormControl; }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private dialogRef: MatDialogRef<DebtorIndDataDialogComponent>,
  ) {
    this.form = this.fb.group({
      birth_date: [null, [Validators.required, Validators.maxLength(255)]],
      birth_place: [null, [Validators.required, Validators.maxLength(255)]],
      mother_name: [null, [Validators.required, Validators.maxLength(255)]],
    });
  }

  submit() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(c => c.markAsDirty());
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: {
        birth_date: this.birth_date.value.format('YYYY-MM-DD'),
        birth_place: this.birth_place.value,
        mother_name: this.mother_name.value,
      },
    };

    this.dialogRef.close(closeValue);
  }
}
